<template>
  <div id="grass">
    <app-navbar></app-navbar>
    <!--    <div class="crossBallone">-->
    <!--      <img v-parallax="0.2" class="img-fluid" src="@/assets/crossRedBallone.png" alt="whiteBallone">-->
    <!--    </div>-->
    <!--    <div class="smallYellowBrickBallone">-->
    <!--      <img v-parallax="0.3" class="img-fluid" src="@/assets/smallYellowBrickBallone.png" alt="yellowTwoBallonHorizont">-->
    <!--    </div>-->
    <!--    <div class="whiteBalls">-->
    <!--      <img class="img-fluid" src="@/assets/whiteBalls.png" alt="whiteBallone">-->
    <!--    </div>-->
    <div class="container pt-lg-4" style="min-height: 80vh;">
      <div class="col-lg-10 offset-lg-1 text-left">
        <h2 class="text-center">
          {{ "FoodStreet.label" | staticTranslation }}
        </h2>
        <p style="text-align: justify;" >{{ "FoodStreet.textOne" | staticTranslation }}</p>
        <!-- <p style="text-align: justify;" >{{ "FoodStreet.textTwo" | staticTranslation }}</p> -->
        <!-- <p>{{ "FoodStreet.textThree" | staticTranslation }}</p> -->
        <!-- <p>{{ "FoodStreet.textFour" | staticTranslation }}</p> -->
      </div>

      <div class="col-lg-10 offset-lg-1">
        <!-- <div class="row pt-lg-4 pb-lg-4 pt-4 pb-4">
          <div
            class="col-lg-4 color-red d-flex justify-content-center align-items-center"
          >
            <img
              width="50%"
              class="img-fluid"
              src="@/assets/Foodstreet/Bistro_Bastardo_Logo_web.png"
              alt="Bistro_Bastardo"
            />
          </div>
          <div class="col-lg-8 color-shadow">
            <h3>Bistro Bastardo</h3>
            <h4>Náměstí Svobody 92/21</h4>
            <p class="mb-1 time">čt–ne 11–21:30</p>
            <p style="text-align: justify;">
              Burrito de Arrachera: Grilované plátky krkovičky marinované v
              ovocných džusech, pivě a směsi koření, servírované v našem burritu
              s fazolemi, sýrem a fermentovaným zelím s jalapenos; nachos, salsa
            </p>
            <a
              class=""
              href="https://www.facebook.com/bistrobastardo/?locale=cs_CZ"
            >
              <div type="button" class="btn mb-lg-4 mb-4">
                {{ "FoodStreet.button" | staticTranslation }}
              </div>
            </a>
            <a>
                          <div v-on:click="$refs.imageModal.show('/streetfood/2024/Bistro Bastardo.jpeg')" type="button"
                               class="btn offset-1 mb-lg-4 mb-4">{{ "FoodStreet.foodImage" | staticTranslation }}
                          </div>
                        </a>
          </div>
        </div>

        <div class="row pt-lg-4 pb-lg-4 pt-4 pb-4">
          <div
            class="col-lg-4 color-red d-flex justify-content-center align-items-center"
          >
            <img
              width="70%"
              class="img-fluid"
              src="@/assets/Foodstreet/BUCHECK_LOGO_web.png"
              alt="Būcheck"
            />
          </div>
          <div class="col-lg-8 color-shadow">
            <h3>Būcheck</h3>
            <h4>Tržnice Brno, Zelný trh 18</h4>
            <p class="mb-1 time">čt-ne 11:30-16:00</p>
            <p style="text-align: justify;">
              Šťavnaté maso, nadýchaná bulka a křupavé hranolky na sádle s
              omáčkou Maraton street food festival.
            </p>
            <a class="" href="https://www.facebook.com/bucheckbrno/">
              <div type="button" class="btn mb-lg-4 mb-4">
                {{ "FoodStreet.button" | staticTranslation }}
              </div>
            </a>
            <a>
              <div
                v-on:click="
                  $refs.imageModal.show('/streetfood/2024/Bucheck.jpeg')
                "
                type="button"
                class="btn offset-1 mb-lg-4 mb-4"
              >
                {{ "FoodStreet.foodImage" | staticTranslation }}
              </div>
            </a>
          </div>
        </div>

        <div class="row pt-lg-4 pb-lg-4 pt-4 pb-4">
          <div
            class="col-lg-4 color-red d-flex justify-content-center align-items-center"
          >
            <img
              width="50%"
              class="img-fluid"
              src="@/assets/Foodstreet/Burger INN - logo.jpeg"
              alt="Burger Inn"
            />
          </div>
          <div class="col-lg-8 color-shadow">
            <h3>Burger Inn</h3>
            <h4>Beethovenova 7</h4>
            <p class="mb-1 time">
              čt-ne 11 - 21:45
            </p>
            <p style="text-align: justify;">
              Maraton Ribs: Vepřová žebra z farmy Škodovi, naše sticky sauce,
              salát z grilované kukuřice, Bacon In(n) Jam, smažené cibulové
              krekry.
            </p>
            <a class="" href="https://burger-inn.cz/?lang=cs">
              <div type="button" class="btn mb-lg-4 mb-4">
                {{ "FoodStreet.button" | staticTranslation }}
              </div>
            </a>
            <a>
              <div
                v-on:click="
                  $refs.imageModal.show('/streetfood/2024/Burger Inn.jpeg')
                "
                type="button"
                class="btn offset-1 mb-lg-4 mb-4"
              >
                {{ "FoodStreet.foodImage" | staticTranslation }}
              </div>
            </a>
          </div>
        </div>

        <div class="row pt-lg-4 pb-lg-4 pt-4 pb-4">
          <div
            class="col-lg-4 color-red d-flex justify-content-center align-items-center"
          >
            <img
              width="70%"
              class="img-fluid"
              src="@/assets/Foodstreet/Morgal_Logo-BW.png"
              alt="Morgal_Logo"
            />
          </div>
          <div class="col-lg-8 color-shadow">
            <h3>Café bar Morgal</h3>
            <h4>Pražákův palác, Husova 18</h4>
            <p class="mb-1 time">čt-ne 10-21</p>
            <p style="text-align: justify;">
              Cizrnový falafel s hummusem, muhammara dipem, mixem salátu a
              tahini-bylinkovým dresingem.
            </p>
            <a class="" href="https://www.facebook.com/CafeMorgal">
              <div type="button" class="btn mb-lg-4 mb-4">
                {{ "FoodStreet.button" | staticTranslation }}
              </div>
            </a>
            <a>
              <div
                v-on:click="
                  $refs.imageModal.show('/streetfood/2024/Cafe Morgal.jpeg')
                "
                type="button"
                class="btn offset-1 mb-lg-4 mb-4"
              >
                {{ "FoodStreet.foodImage" | staticTranslation }}
              </div>
            </a>
          </div>
        </div> -->

        <!--        <div class="row pt-lg-4 pb-lg-4 pt-4 pb-4">-->
        <!--          <div class="col-lg-4 color-red d-flex align-items-center justify-content-center">-->
        <!--            <img width="80%" class="img-fluid" src="@/assets/Foodstreet/pilat_logo.png" alt="pilat_logo">-->
        <!--          </div>-->
        <!--          <div class="col-lg-8 color-shadow">-->
        <!--            <h3>Café Pilát</h3>-->
        <!--            <h4>Kapucínské náměstí 5</h4>-->
        <!--            <p class="mb-1 time">čt–ne 8–21</p>-->
        <!--            <p>Marinované portobello, rýže, avokádo, salát, rajče, řapíkatý celer, mrkev, červená cibule, ředkvička,-->
        <!--              tomatová chilli omáčka-->
        <!--              / masová varianta s kuřetem-->
        <!--            </p>-->
        <!--            <a class="" href="https://www.facebook.com/cafepilat">-->
        <!--              <div type="button" class="btn mb-lg-4 mb-4">{{ "FoodStreet.button" | staticTranslation }}</div>-->
        <!--            </a>-->
        <!--            <a>-->
        <!--              <div v-on:click="$refs.imageModal.show('/streetfood/2. Café Pilát.jpg')" type="button"-->
        <!--                   class="btn offset-1 mb-lg-4 mb-4">{{ "FoodStreet.foodImage" | staticTranslation }}-->
        <!--              </div>-->
        <!--            </a>-->
        <!--          </div>-->
        <!--        </div>-->
<!-- 
        <div class="row pt-lg-4 pb-lg-4 pt-4 pb-4">
          <div
            class="col-lg-4 color-red d-flex justify-content-center align-items-center"
          >
            <img
              width="90%"
              class="img-fluid cosmopolis"
              src="@/assets/Foodstreet/Cosmopolis.png"
              alt="Cosmopolis"
            />
          </div>
          <div class="col-lg-8 color-shadow">
            <h3>Cosmopolis Grill</h3>
            <h4>Kozí 5</h4>
            <p class="mb-1 time">čt 11-22:30, pá-so 11-23:30, ne 12-20:30</p>
            <p style="text-align: justify;">
              Biftéki pita: Mleté hovězí maso na grilu v řeckém, ručně dělaném
              pita chlebu s tzatziki, rajčaty, cibulí a hranolkami + nápoj.
            </p>
            <a class="" href="https://www.facebook.com/cosmopolisgrillBRNO">
              <div type="button" class="btn mb-lg-4 mb-4">
                {{ "FoodStreet.button" | staticTranslation }}
              </div>
            </a>
            <a>
              <div
                v-on:click="
                  $refs.imageModal.show(
                    '/streetfood/2024/Cosmopolis Grill.jpeg'
                  )
                "
                type="button"
                class="btn offset-1 mb-lg-4 mb-4"
              >
                {{ "FoodStreet.foodImage" | staticTranslation }}
              </div>
            </a>
          </div>
        </div> -->

        <!--        <div class="row pt-lg-4 pb-lg-4 pt-4 pb-4">-->
        <!--          <div class="col-lg-4 color-red d-flex justify-content-center align-items-center">-->
        <!--            <img width="40%" class="img-fluid" src="@/assets/Foodstreet/die_kuche.png" alt="die_kuche">-->
        <!--          </div>-->
        <!--          <div class="col-lg-8 color-shadow">-->
        <!--            <h3>Die Küche</h3>-->
        <!--            <h4>Sukova 4 / Kabinet múz</h4>-->
        <!--            <p class="mb-1 time">čt–pá 11–19</p>-->
        <!--            <p>Chilli con tempeh s kešu-limetkovým krémem, grilovanou pšeničnou tortillou, jasmínovou rýží, grilovanými-->
        <!--              kukuřičkami, čerstvým koriandrem-->
        <!--              a salátkem</p>-->
        <!--            <a class="" href="https://www.facebook.com/kabinetmuz">-->
        <!--              <div type="button" class="btn mb-lg-4 mb-4">{{ "FoodStreet.button" | staticTranslation }}</div>-->
        <!--            </a>-->
        <!--            <a>-->
        <!--              <div v-on:click="$refs.imageModal.show('/streetfood/4_Kabinet_múz.jpg')" type="button"-->
        <!--                   class="btn offset-1 mb-lg-4 mb-4">{{ "FoodStreet.foodImage" | staticTranslation }}-->
        <!--              </div>-->
        <!--            </a>-->
        <!--          </div>-->
        <!--        </div>-->

        <!-- <div class="row pt-lg-4 pb-lg-4 pt-4 pb-4">
          <div
            class="col-lg-4 color-red d-flex justify-content-center align-items-center"
          >
            <img
              width="40%"
              class="img-fluid"
              src="@/assets/Foodstreet/Pintxos.png"
              alt="Pintoxs"
            />
          </div>
          <div class="col-lg-8 color-shadow">
            <h3>Don Pintxos</h3>
            <h4>Dominikánské nám. 3</h4>
            <p class="mb-1 time">čt-so 16-23:30</p>
            <p style="text-align: justify;" >Grilované krevety s pečeným lilkem a šafránová majonéza.</p>
            <a class="" href="https://www.facebook.com/donpintxos">
              <div type="button" class="btn mb-lg-4 mb-4">
                {{ "FoodStreet.button" | staticTranslation }}
              </div>
            </a>
            <a>
                          <div v-on:click="$refs.imageModal.show('/streetfood/2024/Don Pintxos.jpeg')" type="button"
                               class="btn offset-1 mb-lg-4 mb-4">{{ "FoodStreet.foodImage" | staticTranslation }}
                          </div>
                        </a>
          </div>
        </div>

        <div class="row pt-lg-4 pb-lg-4 pt-4 pb-4">
          <div
            class="col-lg-4 color-red d-flex align-items-center justify-content-center"
          >
            <img
              width="40%"
              class="img-fluid"
              src="@/assets/Foodstreet/efi_zelnak.png"
              alt="efi_zelnak"
            />
          </div>
          <div class="col-lg-8 color-shadow">
            <h3>EFI Hostinec Zelňák</h3>
            <h4>Zelný trh 4</h4>
            <p class="mb-1 time">
              čt-pá 16:00–0:00, so 11:00–0:00, ne 11:00–22:00
            </p>
            <p style="text-align: justify;">
              Bramborová lokše s trhaným vepřovým masem, čabajkovou marmeládou a
              majonéza z kysaného zelí.
            </p>
            <a class="" href="https://www.facebook.com/EFIHostinecZelnak">
              <div type="button" class="btn mb-lg-4 mb-4">
                {{ "FoodStreet.button" | staticTranslation }}
              </div>
            </a>
            <a>
              <div
                v-on:click="
                  $refs.imageModal.show(
                    '/streetfood/2024/EFI Hostinec Zelňák.jpeg'
                  )
                "
                type="button"
                class="btn offset-1 mb-lg-4 mb-4"
              >
                {{ "FoodStreet.foodImage" | staticTranslation }}
              </div>
            </a>
          </div>
        </div>

        <div class="row pt-lg-4 pb-lg-4 pt-4 pb-4">
          <div
            class="col-lg-4 color-red d-flex justify-content-center align-items-center"
          >
            <img
              width="80%"
              class="img-fluid"
              src="@/assets/Foodstreet/element.png"
              alt="element"
            />
          </div>
          <div class="col-lg-8 color-shadow">
            <h3>Element Bar & Restaurant</h3>
            <h4>Běhounská 7</h4>
            <p class="mb-1 time">čt 17-21:45, pá-so 12-21:45</p>
            <p style="text-align: justify;">
              Arancini s kimči a mozzarellou, Prosecco Millesimato Extra Dry.
            </p>
            <a class="" href="https://www.facebook.com/ElementBrno">
              <div type="button" class="btn mb-lg-4 mb-4">
                {{ "FoodStreet.button" | staticTranslation }}
              </div>
            </a>
            <a>
              <div
                v-on:click="
                  $refs.imageModal.show(
                    '/streetfood/2024/Element Bar & Restaurant.jpeg'
                  )
                "
                type="button"
                class="btn offset-1 mb-lg-4 mb-4"
              >
                {{ "FoodStreet.foodImage" | staticTranslation }}
              </div>
            </a>
          </div>
        </div>

        <div class="row pt-lg-4 pb-lg-4 pt-4 pb-4">
          <div
            class="col-lg-4 color-red d-flex justify-content-center align-items-center"
          >
            <img
              width="50%"
              class="img-fluid"
              src="@/assets/Foodstreet/Go.png"
              alt="Go"
            />
          </div>
          <div class="col-lg-8 color-shadow">
            <h3>Gỗ Brno</h3>
            <h4>Běhounská 4</h4>
            <p class="mb-1 time">čt-ne 10:30-21:15</p>
            <p style="text-align: justify;">
              Bún chả: rýžové nudle, grilované vepřové maso na citronové trávě,
              zelenina, čerstvé bylinky, rybí omáčka.
            </p>
            <a class="" href="https://www.facebook.com/GoBrno">
              <div type="button" class="btn mb-lg-4 mb-4">
                {{ "FoodStreet.button" | staticTranslation }}
              </div>
            </a>
            <a>
              <div
                v-on:click="
                  $refs.imageModal.show('/streetfood/2024/Go Brno.jpeg')
                "
                type="button"
                class="btn offset-1 mb-lg-4 mb-4"
              >
                {{ "FoodStreet.foodImage" | staticTranslation }}
              </div>
            </a>
          </div>
        </div>

        <div class="row pt-lg-4 pb-lg-4 pt-4 pb-4">
          <div
            class="col-lg-4 color-red d-flex justify-content-center align-items-center"
          >
            <img
              width="75%"
              class="img-fluid"
              src="@/assets/Foodstreet/International.png"
              alt="Forkys"
            />
          </div>
          <div class="col-lg-8 color-shadow">
            <h3>Gusto Plzeňka Restaurant</h3>
            <h4>Husova 16 / Hotel International Brno</h4>
            <p class="mb-1 time">čt-so 11:30-21:30, ne 11:30-14:30</p>
            <p style="text-align: justify;" >
              Smažený řízek jako Brno z vepřové kotlety a bramborový salát s
              majonézou.
            </p>
            <a class="" href="https://www.facebook.com/gustoplzenka">
              <div type="button" class="btn mb-lg-4 mb-4">
                {{ "FoodStreet.button" | staticTranslation }}
              </div>
            </a>
            <a>
                          <div v-on:click="$refs.imageModal.show('/streetfood/2024/Plzenka.jpeg')" type="button"
                               class="btn offset-1 mb-lg-4 mb-4">{{ "FoodStreet.foodImage" | staticTranslation }}
                          </div>
                        </a>
          </div>
        </div> -->

        <!--        <div class="row pt-lg-4 pb-lg-4 pt-4 pb-4">-->
        <!--          <div class="col-lg-4 color-red d-flex justify-content-center align-items-center">-->
        <!--            <img width="55%" class="img-fluid" src="@/assets/Foodstreet/larva_mola.png" alt="larva_mola">-->
        <!--          </div>-->
        <!--          <div class="col-lg-8 color-shadow">-->
        <!--            <h3>Larva mola</h3>-->
        <!--            <h4>Poštovská 8 / Pasáž Alfa</h4>-->
        <!--            <p class="mb-1 time">čt a pá 10:30–24, so 11–24, ne 11–20</p>-->
        <!--            <p>Davelské luxusní šťavnaté párečky od Dolejších, jemné bramborové pyré s máslem, smetanou-->
        <!--              a parmazánem, omáčka z uzených paprik</p>-->
        <!--            <a class="" href="https://www.facebook.com/larvamola">-->
        <!--              <div type="button" class="btn mb-lg-4 mb-4">{{ "FoodStreet.button" | staticTranslation }}</div>-->
        <!--            </a>-->
        <!--            <a>-->
        <!--              <div v-on:click="$refs.imageModal.show('/streetfood/10. Larva Mola.JPG')" type="button"-->
        <!--                   class="btn offset-1 mb-lg-4 mb-4">{{ "FoodStreet.foodImage" | staticTranslation }}-->
        <!--              </div>-->
        <!--            </a>-->
        <!--          </div>-->
        <!--        </div>-->

        <!-- <div class="row pt-lg-4 pb-lg-4 pt-4 pb-4">
          <div
            class="col-lg-4 color-red d-flex justify-content-center align-items-center"
          >
            <img
              width="80%"
              class="img-fluid"
              src="@/assets/Foodstreet/UCaipla.png"
              alt="UCaipla"
            />
          </div>
          <div class="col-lg-8 color-shadow">
            <h3>Lokál U Caipla</h3>
            <h4>Kozí 3</h4>
            <p class="mb-1 time">čt-so 11-23, ne 11-21</p>
            <p style="text-align: justify;" >
              Vynikající stroganoff z hovězí svíčkové z českého bio chovu s
              rýží.
            </p>
            <a class="" href="https://www.facebook.com/lokalucaipla">
              <div type="button" class="btn mb-lg-4 mb-4">
                {{ "FoodStreet.button" | staticTranslation }}
              </div>
            </a>
            <a>
              <div
                v-on:click="
                  $refs.imageModal.show('/streetfood/2024/Lokál u Cajpla.jpeg')
                "
                type="button"
                class="btn offset-1 mb-lg-4 mb-4"
              >
                {{ "FoodStreet.foodImage" | staticTranslation }}
              </div>
            </a>
          </div>
        </div> -->

        <!-- <div class="row pt-lg-4 pb-lg-4 pt-4 pb-4">
          <div class="col-lg-4 color-red d-flex justify-content-center align-items-center ">
            <img width="60%" class="img-fluid" src="@/assets/Foodstreet/mikrofarma.png"
                 alt="Mikrofarma Masný trh">
          </div>
          <div class="col-lg-8 color-shadow">
            <h3>Mikrofarma Masný trh</h3>
            <h4>Zelný trh 10</h4>
            <p class="mb-1 time">čt–so 11–21, ne 11–14:30</p>
            <p>Ragú z vyzrálého farmářského hovězího a vepřového masa, jarní cibulka, zauzená chilli majonéza, domácí
              máslová bagetka.</p>
            <a class=""
               href="https://www.mikrofarma.cz/bistro-brno-masny-trh/?fbclid=IwAR0gLj7CstQqWHVMQa79mdpEt6qhz7yOgL_xp_oxRFALH8RIaGw6taY3LsU">
              <div type="button" class="btn mb-lg-4 mb-4">{{ "FoodStreet.button" | staticTranslation }}</div>
            </a>
                        <a>
                          <div v-on:click="$refs.imageModal.show('/streetfood/Mikrofarma.jpeg')" type="button"
                               class="btn offset-1 mb-lg-4 mb-4">{{ "FoodStreet.foodImage" | staticTranslation }}
                          </div>
                        </a>
          </div>
        </div> -->

        <!-- <div class="row pt-lg-4 pb-lg-4 pt-4 pb-4">
          <div
            class="col-lg-4 color-red d-flex justify-content-center align-items-center"
          >
            <img
              width="60%"
              class="img-fluid"
              src="@/assets/Foodstreet/mikrofarma.png"
              alt="mikrofarma"
            />
          </div>
          <div class="col-lg-8 color-shadow">
            <h3>Mikrofarma Jakubák</h3>
            <h4>Běhounská 18 (na Jakubáku)</h4>
            <p class="mb-1 time">čt-so 11-21:30</p>
            <p style="text-align: justify;" >
              Sandwich s pečeným vepřovým kolenem, salátem z kysaného zelí a
              křenovou majonézou v Chlebu Brno.
            </p>
            <a class="" href="https://www.mikrofarma.cz/bistro-brno/">
              <div type="button" class="btn mb-lg-4 mb-4">
                {{ "FoodStreet.button" | staticTranslation }}
              </div>
            </a>
            <a>
              <div
                v-on:click="
                  $refs.imageModal.show(
                    '/streetfood/2024/Mikrofarma Jakubák.jpeg'
                  )
                "
                type="button"
                class="btn offset-1 mb-lg-4 mb-4"
              >
                {{ "FoodStreet.foodImage" | staticTranslation }}
              </div>
            </a>
          </div>
        </div> -->

        <!--        <div class="row pt-lg-4 pb-lg-4 pt-4 pb-4">-->
        <!--          <div class="col-lg-4 color-red d-flex justify-content-center align-items-center">-->
        <!--            <img width="70%" class="img-fluid" src="@/assets/Foodstreet/oceans48.png" alt="oceans48">-->
        <!--          </div>-->
        <!--          <div class="col-lg-8 color-shadow">-->
        <!--            <h3>OCEAN48</h3>-->
        <!--            <h4>Česká 26</h4>-->
        <!--            <p class="mb-1 time">čt a pá 10–17</p>-->
        <!--            <p>Strips quesadilla, cheddar, kukuřice, jalapeños a barbecue omáčka</p>-->
        <!--            <a class="" href="https://www.ocean48.cz/Obchod/Prodejny?id=ceska">-->
        <!--              <div type="button" class="btn mb-lg-4 mb-4">{{ "FoodStreet.button" | staticTranslation }}</div>-->
        <!--            </a>-->
        <!--            <a>-->
        <!--              <div v-on:click="$refs.imageModal.show('/streetfood/14. a 15. Oceans.JPG')" type="button"-->
        <!--                   class="btn offset-1 mb-lg-4 mb-4">{{ "FoodStreet.foodImage" | staticTranslation }}-->
        <!--              </div>-->
        <!--            </a>-->
        <!--          </div>-->
        <!--        </div>-->

        <!-- <div class="row pt-lg-4 pb-lg-4 pt-4 pb-4">
          <div
            class="col-lg-4 color-red d-flex justify-content-center align-items-center"
          >
            <img
              width="60%"
              class="img-fluid"
              src="@/assets/Foodstreet/restaurant.png"
              alt="ocean´s"
            />
          </div>
          <div class="col-lg-8 color-shadow">
            <h3>Rễ restaurant Brno</h3>
            <h4>Běhounská 8</h4>
            <p class="mb-1 time">čt-ne 10:30-21:15</p>
            <p style="text-align: justify;" >
              Salmon bowl: sushi rýže, losos, salát, ředkev oshinko, edamame,
              kukuřice, ředkvička, avokádo, mrkev, okurka, wakame, furikake
            </p>
            <a
              class=""
              href="https://rebrno.cz/?ved=2ahUKEwiVx6KYkeCHAxXzSfEDHTOgGWkQgU96BAgpEAQ"
            >
              <div type="button" class="btn mb-lg-4 mb-4">
                {{ "FoodStreet.button" | staticTranslation }}
              </div>
            </a>
            <a>
              <div
                v-on:click="
                  $refs.imageModal.show(
                    '/streetfood/2024/Re.jpeg'
                  )
                "
                type="button"
                class="btn offset-1 mb-lg-4 mb-4"
              >
                {{ "FoodStreet.foodImage" | staticTranslation }}
              </div>
            </a>
          </div>
        </div>

        <div class="row pt-lg-4 pb-lg-4 pt-4 pb-4">
          <div
            class="col-lg-4 color-red d-flex justify-content-center align-items-center"
          >
            <img
              width="50%"
              class="img-fluid"
              src="@/assets/Foodstreet/singha.png"
              alt="singha"
            />
          </div>
          <div class="col-lg-8 color-shadow">
            <h3>Singha Thai Restaurant</h3>
            <h4>Orlí 6</h4>
            <p class="mb-1 time">čt 11-22 pá 11-23 so-ne 12-22</p>
            <p style="text-align: justify;" >
              Phad thai s hovězím masem nebo tofu; rýžové nudle ve sladko-kyselé
              tamarinové omáčce s mrkví, sójovými klíčky, vajíčkem, arašídy,
              jarní cibulkou a limetou.
            </p>
            <a
              class=""
              href="https://www.facebook.com/Singha-Thai-Restaurant-Brno-844188172447859"
            >
              <div type="button" class="btn mb-lg-4 mb-4">
                {{ "FoodStreet.button" | staticTranslation }}
              </div>
            </a>
            <a>
              <div
                v-on:click="
                  $refs.imageModal.show('/streetfood/2024/Singa Thai.jpeg')
                "
                type="button"
                class="btn offset-1 mb-lg-4 mb-4"
              >
                {{ "FoodStreet.foodImage" | staticTranslation }}
              </div>
            </a>
          </div>
        </div> -->

        <!-- <div class="row pt-lg-4 pb-lg-4 pt-4 pb-4">
          <div class="col-lg-4 color-red d-flex justify-content-center align-items-center">
            <img width="60%" class="img-fluid" src="@/assets/Foodstreet/Tip_organizátorů_MSFF.png"
                 alt="Doporučení na snídani">
          </div>
          <div class="col-lg-8 color-shadow">
            <h3>Doporučení na snídani</h3>
            <h4>Dvořákova 12</h4>
            <p></p>
            <p>
              Pro všechny návštěvníky MSFF máme ještě jedno doporučení. Pokud byste chtěli skvěle posnídat, Eggo Food
              Truck & Bistro na ulici Dvořákova 12 má otevřeno 8–12 hod., v neděli pak 9–13 hod.
            </p> -->
        <!--            <a class="" href="https://burger-inn.cz/?lang=cs">-->
        <!--              <div type="button" class="btn mb-lg-4 mb-4">{{ "FoodStreet.button" | staticTranslation }}</div>-->
        <!--            </a>-->
        <!--            <a>-->
        <!--              <div v-on:click="$refs.imageModal.show('/streetfood/16. Singha Thai lepší.JPG')" type="button"-->
        <!--                   class="btn offset-1 mb-lg-4 mb-4">{{ "FoodStreet.foodImage" | staticTranslation }}-->
        <!--              </div>-->
        <!--            </a>-->
        <!-- </div>
        </div> -->

        <!-- <h2 class="text-center">Mapa partnerských podniků</h2>
        <div class="text-center pb-lg-4 pb-4">
          <img
            class="img-fluid text-center"
            src="@/assets/Foodstreet/mapa_2024.jpg"
            alt="Mapa"
          />
        </div> -->
      </div>
      <image-modal ref="imageModal" />
    </div>
    <!--    <div class="box-red"></div>-->
    <app-footer></app-footer>
  </div>
</template>

<script>
import navbar from "@/components/base/navbar";
import footer from "@/components/base/footer";
import ImageModal from "@/components/base/ImageModal";

export default {
  name: "FoodStreet",
  components: {
    "image-modal": ImageModal,
    "app-navbar": navbar,
    "app-footer": footer,
  },
  data() {
    return {};
  },
};
</script>

<style scoped>
h1 {
  font-size: 3.5em;
  padding-top: 3em;
  padding-bottom: 50px;
  font-family: "Transcript Pro";
}

h2 {
  padding-top: 7rem;
  padding-bottom: 2em;
  font-size: 2.5em;
  font-family: "Transcript Pro";
}

h3 {
  margin-bottom: 0;
  padding-top: 0.7rem;
  font-size: 1.8rem;
  font-family: "Transcript Pro";
}

h4 {
  margin-bottom: 0;
  padding-top: 0.1rem;
  font-family: "Transcript Pro";
  font-size: 1.2rem;
}

h5 {
  color: black;
  font-size: 1.4em;
  font-family: "Transcript Pro";
}

p {
  font-family: "Transcript Mono";
}

#grass {
  background-color: #f3ba53;
  height: 100vh;
}

.table td,
.table,
th {
  border: none;
}

.table th {
  font-family: "Transcript Pro";
  font-size: 1.5rem;
}

.table td {
  font-family: "Transcript Mono";
  font-size: 1rem;
}

.table td span {
  padding-right: 20px;
}

.redBalloneHalf {
  position: absolute;
  text-align: end;
  right: 0;
  width: 10%;
  top: 300%;
}

.crossBallone {
  width: 12%;
  position: absolute;
  top: 12%;
  left: 2%;
}

.color-red {
  background-color: #ed764e;
}

@media screen and (max-width: 575px) {
  .color-red {
    background-color: #ed764e;
    min-height: 14em;
  }
}

.color-shadow {
  background-color: #eae7d6;
}

.box-red {
  z-index: -1;
  position: absolute;
  height: 200vh;
  text-align: end;
  top: 80%;
  right: 0;
  width: 55%;
  background-color: #ed764e;
}

.btn {
  padding: 4px 12px;
  border-radius: 0px;
  color: #152326;
  font-family: "Transcript Pro";
  background-color: #f3ba53;
}

.btn:hover {
  background-color: #152326;
  color: #eae7d6;
}

.time {
  padding-top: 0;
}

.smallYellowBrickBallone {
  width: 13%;
  z-index: 1;
  position: absolute;
  text-align: end;
  right: 4%;
  top: 140vh;
}

.whiteBalls {
  width: 6%;
  position: absolute;
  top: 220vh;
  left: 5%;
}
</style>
