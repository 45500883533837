<template>
  <div id="myImageModal" tabIndex="-1" v-bind:style="visible ? 'display: block' : 'none'" class="modal">
    <span v-on:click="exit" class="close">✕</span>
    <img :src="url" class="modal-content img-fluid" id="galleryModalImage">
  </div>
</template>
<script>
export default {
  name: "ImageModal",
  watch: {},
  data() {
    return {
      visible: false,
      url: ""
    }
  },
  methods: {
    exit: function () {
      this.visible = false;
      this.url = ""
      document.getElementsByTagName("nav")[0].style.display = 'flex';
    },
    show: function (newValue) {
      if (newValue) {
        this.visible = true;
        this.url = newValue;
        document.getElementsByTagName("nav")[0].style.display = 'none';
      }
    }
  }
}
</script>

<style scoped>

a {
  color: #152326;
  text-decoration: none;
  font-size: 1em;
  font-family: 'Transcript Mono';
}

.modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  padding-top: 100px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.9); /* Black w/ opacity */
}

/* Modal Content (image) */
.modal-content {
  margin: auto;
  display: block;
  width: auto;
  max-height: 80vh;
}

/* Caption of Modal Image */
#caption {
  margin: auto;
  display: block;
  width: 80%;
  max-width: 700px;
  text-align: center;
  color: #ccc;
  padding: 10px 0;
  height: 150px;
}

/* Add Animation */
.modal-content, #caption {
  -webkit-animation-name: zoom;
  -webkit-animation-duration: 0.6s;
  animation-name: zoom;
  animation-duration: 0.6s;
}

@-webkit-keyframes zoom {
  from {
    -webkit-transform: scale(0)
  }
  to {
    -webkit-transform: scale(1)
  }
}

@keyframes zoom {
  from {
    transform: scale(0)
  }
  to {
    transform: scale(1)
  }
}

/* The Close Button */
.close {
  position: absolute;
  top: 15px;
  right: 35px;
  padding: 3px 7px;
  background-color: #ffbe46;
  color: #152326;
  border-radius: 10px;
  font-size: 25px;
  font-weight: bold;
  transition: 0.3s;
  opacity: 1;
}

.close:hover,
.close:focus {
  color: white;
  text-decoration: none;
  cursor: pointer;
}

/* 100% Image Width on Smaller Screens */
@media only screen and (max-width: 700px) {
  .modal-content {
    width: 100%;
  }
}

@media screen and (max-width: 991px) {
  #gallery {
    padding-top: 1em;
  }
}

@keyframes textPhoto {
  100% {
    left: 7%;
    color: white;
    top: 7%;
    position: absolute;
    z-index: 3;
  }

}

@media screen and (max-width: 400px) {
  #photoSlider {
    padding-top: 4em;
  }

  .prev,
  .next {
    cursor: pointer;
    position: absolute;
    background-color: #f3ba53;
    top: 30%;
    width: auto;
    padding: 5px;
    margin-top: -22px;
    color: #152326;
    font-weight: bold;
    font-size: 20px;
    border-radius: 0 3px 3px 0;
    user-select: none;
  }

  .next {
    right: 0;
    border-radius: 3px 0 0 3px;
  }
}

@media screen and (max-width: 400px) {
  #photoSlider {
    padding-top: 4em;
  }

  .prev,
  .next {
    top: 14%;
  }
}

@media screen and (min-width: 401px) and (max-width: 500px) {
  #photoSlider {
    padding-top: 5em;
  }

  .prev,
  .next {
    cursor: pointer;
    position: absolute;
    background-color: #f3ba53;
    top: 25%;
    width: auto;
    padding: 5px;
    margin-top: -22px;
    color: #152326;
    font-weight: bold;
    font-size: 20px;
    border-radius: 0 3px 3px 0;
    user-select: none;
  }

  .next {
    right: 0;
    border-radius: 3px 0 0 3px;
  }
}

@media screen and (min-width: 501px) {
  .prev,
  .next {
    cursor: pointer;
    position: absolute;
    background-color: #f3ba53;
    top: 50%;
    width: auto;
    padding: 13px;
    margin-top: -22px;
    color: #152326;
    font-weight: bold;
    font-size: 25px;
    border-radius: 0 3px 3px 0;
    user-select: none;
  }

  .next {
    right: 0;
    border-radius: 3px 0 0 3px;
  }
}

@media (min-width: 1025px) {
  #photoSlider {
    height: 90vh;
    overflow: hidden;
  }
}

a:hover {
  text-decoration: none;
  color: #eae7d6;
}

img {
  width: 100%;
}

</style>
